import React, {useState, useEffect} from 'react';
import { useTranslation } from "react-i18next"

import ProductTemplate from '../../templates/productTemplate';

const ProductList = () => {
  const { t } = useTranslation();

  // useEffect(() => {
  //   window.dataLayer = window.dataLayer || []
  //   window.dataLayer &&
  //   window.dataLayer.push({
  //     pageURL: "/product-2",
  //     testovani: websiteVersion,
  //   })
  // }, [websiteVersion])

  return (
  <>
    <li>{t("home.program2.productListItem1")}</li>
    <li>{t("home.program2.productListItem2")}</li>
    <li>{t("home.program2.productListItem3")}</li>
  </>
  )
}

const Product2 = () => {
  const { t } = useTranslation();

  return (
  <ProductTemplate
    productId="2"
    title={t("home.program2.program2title")}
    productList={ProductList}
  />
  )
}  

export default Product2
